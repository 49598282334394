// src/UserPool.js
import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: (window.env?.REACT_APP_USER_POOL_ID) ?? process.env.REACT_APP_USER_POOL_ID,
    ClientId: (window.env?.REACT_APP_CLIENT_ID) ?? process.env.REACT_APP_CLIENT_ID
};

const UserPool = new CognitoUserPool(poolData);

export default UserPool;
