import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';  // Ensure this path is correct to your theme config
import Header from './components/Header'; // Adjust path as needed
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { UserContext } from './contexts/UserContext'; // Import the context

function App() {
  const [user, setUser] = useState(null);

  const handleLoginSuccess = (userAttributes) => {
    setUser(userAttributes);
    console.log(userAttributes);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <UserContext.Provider value={{user, setUser}}> {/* Provide the user state */}
          <Header />  
          <Routes>
            <Route path="/login" element={user ? <Navigate to="/" replace /> : <Login onLoginSuccess={handleLoginSuccess} />} />
            <Route path="/" element={user ? <Dashboard /> : <Navigate to="/login" replace />} />
          </Routes>
        </UserContext.Provider> {/* Close the provider */}
      </Router>
    </ThemeProvider>
  );
}

export default App;
