// src/components/Dashboard.js
import React, { useState } from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import UserManagement from './UserManagement'; // Import the UserManagement component

function Dashboard() {
  return (
    <Card style={{ margin: 20 }}>
      <CardContent>
        <UserManagement />
      </CardContent>
    </Card>
  );
}

export default Dashboard;
