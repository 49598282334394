import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  Button,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GroupAdd from "@mui/icons-material/GroupAdd";
import PersonAddDisabled from "@mui/icons-material/PersonAddDisabled";
import ActionsMenu from "./ActionsMenu";
import GroupMenu from "./GroupMenu";

import axios from "axios";
import { Label } from "@mui/icons-material";
import { UserContext } from '../contexts/UserContext'; // Import the context

let TenantAPIURL = (window.env?.REACT_APP_TENANT_API_URL) ?? process.env.REACT_APP_TENANT_API_URL;
console.log("TenantAPIURL:", TenantAPIURL);

function UserManagement() {

  const [users, setUsers] = useState([]);
  const { user } = useContext(UserContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [groupFilter, setGroupFilter] = useState("");

  const onGroupFilterChange = (event) => {
    setGroupFilter(event.target.value);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      // Access the environment variable directly with process.env
      const apiUrl = `${TenantAPIURL}/users`;
      const token = user.access_token;
      const response = await axios.get(apiUrl, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
          bgcolor: "primary.main",
          color: "primary.contrastText",
        }}
      >
        <Typography
          variant="h6"
          component="h6"
          sx={{ color: "white", fontWeight: "bold" }}
        >
          User Management
        </Typography>
        <IconButton
          aria-label="refresh"
          size="large"
          onClick={fetchUsers}
          sx={{
            color: "white", // Set the icon color to white
            backgroundColor: "primary.main", // Example background color
            "&:hover": {
              backgroundColor: "primary.dark", // Darken the background on hover
            },
          }}
        >
          <RefreshIcon fontSize="inherit" />
        </IconButton>
        <GroupMenu />
        <ActionsMenu />
      </Box>
      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Display Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Email Verified</TableCell>
              <TableCell>User Created</TableCell>
              <TableCell>Last Modified</TableCell>
              <TableCell align="center">Enabled</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(users) ? users.map((user) => (
              <TableRow key={user.Username}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell align="center">
                  {user.email_verified ? "✔️" : "❌"}
                </TableCell>
                <TableCell>{user.UserCreateDate}</TableCell>
                <TableCell>{user.UserLastModifiedDate}</TableCell>
                <TableCell align="center">
                  {user.Enabled ? "✔️" : "❌"}
                </TableCell>
                <TableCell>{user.UserStatus}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => console.log("Remove from group")}>
                    <GroupAdd />
                  </IconButton>
                  <IconButton onClick={() => console.log("Deactivate")}>
                    <PersonAddDisabled />
                  </IconButton>
                </TableCell>
              </TableRow>
            )) : users}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default UserManagement;
