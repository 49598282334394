// src/components/Login.js
import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, Container } from '@mui/material';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

import UserPool from '../UserPool'; // Adjust path as necessary

function Login({ onLoginSuccess }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [user, setUser] = useState(null); // To retain the Cognito user during the new password flow
    const [error, setError] = useState('');
    const [isChangingPassword, setIsChangingPassword] = useState(false); // To control the UI state for password change
    const [fullName, setFullName] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');  // Clear previous errors
    
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool
        });
    
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        });
    
        user.authenticateUser(authDetails, {
            onSuccess: (result) => {
                console.log('Authentication successful!');
                onLoginSuccess({
                    name: result.idToken.payload['name'] || 'Jane Doe', // Getting the name from token if available
                    id_token: result.idToken.jwtToken,
                    access_token: result.accessToken.jwtToken,
                    refresh_token: result.refreshToken.token
                });

                    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');  // Clear previous errors
    
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool
        });
    
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        });
    
        user.authenticateUser(authDetails, {
            onSuccess: (result) => {
                console.log('Authentication successful!');
                onLoginSuccess({
                    name: result.idToken.payload['name'] || 'Jane Doe', // Getting the name from token if available
                    id_token: result.idToken.jwtToken,
                    access_token: result.accessToken.jwtToken,
                    refresh_token: result.refreshToken.token
                });
            },
            onFailure: (err) => {
                console.error('Authentication failed:', err);
                setError('Login failed: ' + err.message); // More detailed error message
            },
            newPasswordRequired: (data) => {
                setUser(user); // Save user session
                setIsChangingPassword(true); // Show new password form
                setError('Please set a new password.');
            }
        });
    };
            },
            onFailure: (err) => {
                console.error('Authentication failed:', err);
                setError('Login failed: ' + err.message); // More detailed error message
            },
            newPasswordRequired: (data) => {
                setUser(user); // Save user session
                setIsChangingPassword(true); // Show new password form
                setError('Please set a new password.');
            }
        });
    };

    const handleNewPasswordSubmit = (event) => {
        event.preventDefault();
        if (newPassword !== confirmNewPassword) {
            setError('Passwords do not match.');
            return;
        }
        const userAttr = {
            name: fullName,
        };

        if (user) {
            try {
                user.completeNewPasswordChallenge(newPassword, userAttr,{
                    onSuccess: (result) => {
                        console.log("Password changed successfully:", result);
                        // Handle successful password change, e.g., redirect or login the user
                    },
                    onFailure: (err) => {
                        console.error("Failed to change password:", err);
                        setError(`Failed to set new password: ${err.message}`);
                    }
                });
            } catch (error) {
                console.error('Error during the new password challenge:', error);
                setError('An unexpected error occurred.');
            }
        } else {
            console.error('No Cognito user instance available');
            setError('An unexpected error occurred. Please restart the process.');
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={6} style={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                <Typography component="h1" variant="h5">
                    {isChangingPassword ? 'Set New Password' : 'Sign In'}
                </Typography>
                {isChangingPassword ? (
                    <form onSubmit={handleNewPasswordSubmit} style={{ width: '100%', marginTop: 1 }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="fullName"
                            label="Full Name"
                            type="text"
                            id="full-name"
                            autoComplete="full-name"
                            value={fullName}
                            onChange={e => setFullName(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="newPassword"
                            label="New Password"
                            type="password"
                            id="new-password"
                            autoComplete="new-password"
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="confirmNewPassword"
                            label="Confirm New Password"
                            type="password"
                            id="confirm-new-password"
                            autoComplete="new-password"
                            value={confirmNewPassword}
                            onChange={e => setConfirmNewPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ margin: '24px 0px 16px' }}
                        >
                            Update Password
                        </Button>
                    </form>
                ) : (
                    <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 1 }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ margin: '24px 0px 16px' }}
                        >
                            Login
                        </Button>
                        {error && <Typography color="error">{error}</Typography>}
                    </form>
                )}
            </Paper>
        </Container>
    );
}

export default Login;
